import React, { useEffect, useState } from "react"
import { ParticipateForm } from "../../components/Winter2022/ParticipateForm"
import AgeGate2 from "../../components/agegate"
import { graphql } from "gatsby"

const ParticipateEvent = ({ data }) => {
  const [age, setAge] = useState(true)
  const [events, setEvents] = useState([])

  useEffect(() => {
    let local = sessionStorage.getItem("age")
    if (local) setAge(false)
    const places = data?.allWinter2022?.nodes.filter(elem => {
      return elem.visible
    })
    setEvents(places)
  }, [])

  return !age ? (
    <ParticipateForm eventos={events} />
  ) : (
    <AgeGate2 setAge={setAge} />
  )
}

export default ParticipateEvent

export const query = graphql`
  query ParticipateWinter2022Query {
    allWinter2022 {
      nodes {
        date
        description
        eventName
        id
        img
        img_mobile
        index
        location
        visible
      }
    }
  }
`
